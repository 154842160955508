import * as actionTypes from '../Actions/actionTypes';

const initialState = {
  list: false,
  fields: false,
  sortKey: 'id',
  sortOrder: 'desc',
  searchData: false,
  currentPage: 1,
  rowsPerPage: 15,
  resultsCount: 0,
  pageCount: null,
  selectedOrder: false,
  skuSearch: [],
};

const setSupplierOrdersList = (state, action) => {
  return {
    ...state,
    list: action.supplierOrders.list,
  };
};

const setSelectedOrder = (state, action) => {
  action.order.needSave = false;

  return {
    ...state,
    selectedOrder: action.order,
  };
};

const setNameSearchresult = (state, action) => {
  if (!Array.isArray(action.products)) {
    action.products = [];
  }
  return {
    ...state,
    skuSearch: action.products,
  };
};

const setBarcodeSearchresult = (state, action) => {
  if (!Array.isArray(action.products)) {
    action.products = [];
  }
  return {
    ...state,
    barcodeSearch: action.products,
  };
};

const editSelectedOrder = (state, action) => {
  let priceArray = [...state.selectedOrder.products];

  switch (action.valueType) {
    case 'idState':
      if (![1, 2].includes(action.value)) {
        return {...state};
      }
      return {
        ...state,
        selectedOrder: {
          ...state.selectedOrder,
          needSave: true,
          idState: action.value,
        },
      };
    case 'idSupplier':
      return {
        ...state,
        selectedOrder: {
          ...state.selectedOrder,
          needSave: true,
          idSupplier: action.value,
        },
      };
    case 'idShelf':
      return {
        ...state,
        selectedOrder: {
          ...state.selectedOrder,
          needSave: true,
          idShelf: action.value,
        },
      };
    case 'name':
      return {
        ...state,
        selectedOrder: {
          ...state.selectedOrder,
          needSave: true,
          name: action.value,
        },
      };
    case 'price':
      priceArray.forEach((el) => {
        if (el.id === action.id) {
          el.price = action.value;
        }
      });

      return {
        ...state,
        selectedOrder: {
          ...state.selectedOrder,
          needSave: true,
          products: priceArray,
        },
      };
    case 'discount':
      priceArray.forEach((el) => {
        if (el.id === action.id) {
          el.discount = action.value;
        }
      });

      return {
        ...state,
        selectedOrder: {
          ...state.selectedOrder,
          needSave: true,
          products: priceArray,
        },
      };
    case 'fixedCosts':
      priceArray.forEach((el) => {
        if (el.id === action.id) {
          el.fixedCosts = action.value;
        }
      });

      return {
        ...state,
        selectedOrder: {
          ...state.selectedOrder,
          needSave: true,
          products: priceArray,
        },
      };
    case 'pricePerUnit':
      priceArray.forEach((el) => {
        if (el.id === action.id) {
          el.pricePerUnit = action.value;
        }
      });

      return {
        ...state,
        selectedOrder: {
          ...state.selectedOrder,
          needSave: true,
          products: priceArray,
        },
      };
    case 'totalPerUnit':
      priceArray.forEach((el) => {
        if (el.id === action.id) {
          if (action.value !== 0 && action.value !== null) {
            el.totalPerUnit = action.value;
          } else {
            el.totalPerUnit = el.pricePerUnit * (el.orderedQuantity || 1);
          }
        }
      });

      return {
        ...state,
        selectedOrder: {
          ...state.selectedOrder,
          needSave: true,
          products: priceArray,
        },
      };
    case 'orderedQuantity':
      let array = [...state.selectedOrder.products];

      array.forEach((el) => {
        if (el.id === action.id) {
          el.orderedQuantity = action.value;
        }
      });

      return {
        ...state,
        selectedOrder: {
          ...state.selectedOrder,
          needSave: true,
          products: array,
        },
      };
    case 'arrivedQuantity':
      let array2 = [...state.selectedOrder.products];

      array2.forEach((el) => {
        if (el.id === action.id) {
          el.arrivedQuantity = action.value;
        }
      });

      return {
        ...state,
        selectedOrder: {
          ...state.selectedOrder,
          needSave: true,
          products: array2,
        },
      };
    case 'idCombination':
      let array3 = [...state.selectedOrder.products];

      array3.forEach((el) => {
        if (el.id === action.id) {
          el.idCombination = action.value;
        }
      });

      return {
        ...state,
        selectedOrder: {
          ...state.selectedOrder,
          needSave: true,
          products: array3,
        },
      };
    case 'idSkuOwner':
      return {
        ...state,
        selectedOrder: {
          ...state.selectedOrder,
          needSave: true,
          idSkuOwner: action.value,
        },
      };
    default:
      return {...state};
  }
};

const unsetSelectedOrderProduct = (state, action) => {
  let array = [];

  state.selectedOrder.products.forEach((el, index) => {
    if (el.id !== action.id) {
      array.push(el);
    }
  });

  return {
    ...state,
    selectedOrder: {...state.selectedOrder, products: array},
  };
};

const pushSelectedOrderProduct = (state, action) => {
  let array = [...state.selectedOrder.products, action.product];

  return {
    ...state,
    selectedOrder: {...state.selectedOrder, products: array},
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_SUPPLIER_ORDERS_LIST:
      return setSupplierOrdersList(state, action);
    case actionTypes.SET_SELECTED_SUPPLIER_ORDER:
      return setSelectedOrder(state, action);
    case actionTypes.EDIT_SELECTED_SUPPLIER_ORDER:
      return editSelectedOrder(state, action);
    case actionTypes.SET_NAME_SEARCH_RESULT:
      return setNameSearchresult(state, action);
    case actionTypes.SET_BARCODE_SEARCH_RESULT:
      return setBarcodeSearchresult(state, action);
    case actionTypes.UNSET_SELECTED_SUPPLIER_ORDER_PRODUCT:
      return unsetSelectedOrderProduct(state, action);
    case actionTypes.PUSH_SELECTED_SUPPLIER_ORDER_PRODUCT:
      return pushSelectedOrderProduct(state, action);
    default:
      return state;
  }
};

export default reducer;
